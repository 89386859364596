import React, { useState, useEffect, Component, useRef } from 'react';

function Home() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const divRefs = useRef([]); // An array of refs to hold references to each div
    const [visibleDivs, setVisibleDivs] = useState([]); // Array to track which divs are visible

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Select all divs that have the 'animate-on-scroll' class
        const elementsToAnimate = document.querySelectorAll('.animateOnScroll');

        // Intersection Observer callback function
        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate'); // Add 'animate' class to visible divs
                observer.unobserve(entry.target); // Unobserve once animation is applied
            }
            });
        };

        // Create an IntersectionObserver instance
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.25, // Trigger when 10% of the element is visible
        });

        // Observe each selected div
        elementsToAnimate.forEach((el) => observer.observe(el));

        return () => {
          window.removeEventListener('scroll', handleScroll);

        };
      }, []);


      

  return (
    <div className="home-wrap">
        <Menu />
        <div className="background-banner1"
             style={{backgroundImage: "url('/img/compare.webp')"}}
        >
            <div className={`main-title Urbainst ${isScrolled ? 'scrolled' : 'unscrolled'}`}>
                <img src={'/img/RockyMountainSolarShine_White4.webp'} />
            </div>
        </div>
        <div className='section2' >
            <div className="section2-title Bebas">
                Protect Your Solar Investments
            </div>
            <div className={`triple-row1`}

            >
                <div className="item item1 Urbanist animateOnScroll">
                    <div className="item-image item-img1" style={{backgroundImage:`url('/img/efficiency.webp')`}}>
                    </div>
                        
        <div className="text-wrap">
                    <div className="title Bebas">Efficiency Gain</div>
                                {/* <img src={'/dust.png'} /> */}
                                <div className="item-text">
                                    Annually, solar power plants suffer a loss of almost 40% efficiency (0.2% daily, 1% to 5% in a month) due to dust layer build up.<br/>
                                    <div className="vertical-divider" />
                                    If you do not regularly maintain your solar panels, you will see a <span style={{fontWeight: "bold", fontStyle: "italic"}}>significant decrease in ROI</span> in terms of spend ($) per kWh generated.
                                </div>
            </div>                        

                    

                </div>

                <div className="item item2 Urbanist animateOnScroll">
                    <div className="item-image item-img2" style={{backgroundImage:`url('/img/proper_cleaning.webp')`}}>
                    </div>

                    <div className="text-wrap">
                        <div className="title Bebas">Proper Cleaning</div>
                        {/* <img src={'/water.png'} /> */}
                        <div className="item-text">
                            Today, the most effective mitigation technique is physically washing the surfaces of the solar devices with a distilled water solution.<br/>
                            <div className="vertical-divider" />
                            Our low-touch cleaning method allows us to rid your panels of any dust and debris without damaging panel surfaces.
                        </div>
                        </div>
                    
                </div>

                <div className="item item3 Urbanist animateOnScroll">
                    <div className="item-image item-img3" style={{backgroundImage:`url('/img/enhance_lifespan.webp')`}}>
                    </div>
                    <div className="text-wrap">
                        <div className="title Bebas">Enhance Lifespan</div>
                        {/* <img src={'/plus.png'} /> */}
                        <div className="item-text">
                            Annual cleaning has been proven to increase the lifespan of your solar panels while ensuring you are operating at max efficiency.<br/>
                            <div className="vertical-divider" />
                            We also remove bird-nests and check for any malfunctions or other damages to the panels. More regular maintenance leads to longer lifespans
                        </div>
                    </div>

                </div>
            </div>
            {/* <img className="Asset1" src={'/width_bw_mountain.png'} /> */}
        </div>
        <div className='section3' >
        <div className="section3-title Bebas">
                PRICING
            </div>
            <div className={`pricing-row`}>
                <div className="price animateOnScroll">
                    <div className="price-title Bebas">
                        MONTHLY
                    </div>
                    
                    
                <div className="pricing-details-wrap">
                    <div className="price-price Bebas">
                            <span className="smaller-dollar-span">$</span>8/
                        </div>
                        <div className="price-panel Urbanist">
                            panel
                        </div>
                </div>          

                </div>
                <div className="spacer"></div>
                <div className="price animateOnScroll">
                    <div className="price-title Bebas">
                        QUARTERLY
                    </div>
                    <div className="pricing-details-wrap">
                    <div className="price-price Bebas">
                    <span className="smaller-dollar-span">$</span>10/
                        </div>
                        <div className="price-panel Urbanist">
                            panel
                        </div>
                </div>
                </div>
                <div className="spacer"></div>
                <div className="price animateOnScroll">
                    <div className="price-title Bebas">
                        ONE-TIME
                    </div>
                    <div className="pricing-details-wrap">
                    <div className="price-price Bebas">
                    <span className="smaller-dollar-span">$</span>15/
                        </div>
                        <div className="price-panel Urbanist">
                            panel
                        </div>
                </div>
                </div>
            </div>
            <img className="Asset2" src={'/img/width_bw_mountain.webp'} />
        </div>
    </div>
  );
}





const Menu = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    return (
        <div className={`menu-full-length ${isScrolled ? 'scrolled' : 'unscrolled'}`}>
                <div className="menu-title Urbanist">
                <img src={'/img/Logo_Words.webp'} />
                </div>
                <div className="menu-number Urbanist">
                    970-000-0000
                </div>
            {/* <div className={`menu-container ${isScrolled ? 'scrolled' : 'unscrolled'}`}>
                <div className="hamburger" onClick={toggleMenu}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                </div>
                {isMenuOpen && (
                <div className="menu">
                    <ul>
                    <li><a href="#home">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                )}
            </div> */}
        </div>
    );
  };














export default Home;
