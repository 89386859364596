import { useEffect, useState } from 'react';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

import logo from './logo.svg';
import './App.css';

import DesktopApp from './pages_desktop/index';

function App() {

  useEffect(()=>{


  },[])

  console.log(isMobile,isTablet,isDesktop);

 
    return (
      <DesktopApp 
      />
    );
}

export default App;
