import Home from './Home';

function DesktopApp() {
  return (
    <div className="deskptop-wrapper">
            <Home 

            />
    </div>

  );
}

export default DesktopApp;
